import { useEffect, useState, useContext, createContext, ReactNode} from "react";
import { getAuth, onAuthStateChanged, User, signOut } from "firebase/auth";

interface UserProviderProps {
    children: ReactNode;
}

interface UserContextType {
    user: User | null;
    loading: boolean;
    logOut: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function useUserContext() {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within a UserProvider');
    }
    return context;
}


export default function UserProvider({ children }: UserProviderProps) {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setUser(user);
            setLoading(false);
        });
        return () => unsubscribe();
    }, []);

    const logOut = async () => {
        try {
            await signOut(auth);
            setUser(null);
        } catch (e: any) {
            console.log(`Error signing out: ${e.message}`)
        }
    }

    return (
        <UserContext.Provider value={{ user, loading, logOut }}>
            {children}
        </UserContext.Provider>
);

}