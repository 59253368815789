import React, { useEffect } from 'react';
import {useUserContext} from '../contexts/UserProvider';
import '../styles/home.css'; // Custom styles
import bg0 from "../assets/img/bg/main-bg.jpg";
import bg1 from "../assets/img/bg/main-bg1.jpg";
import bg2 from "../assets/img/bg/main-bg2.jpg";
import bg3 from "../assets/img/bg/main-bg3.jpg";
import HoloRaySideMenu from "../components/HoloRaySideMenu";
import feather from 'feather-icons';

const HomePage: React.FC = () => {
    const { user } = useUserContext();
    useEffect(() => {
      feather.replace(); 
    }, []);

  return (
    <div className="shop-wrapper has-background-image">
      <div className="search-overlay"></div>   

      <div className="search-input-wrapper is-desktop is-hidden">
          <div className="field">
          <div className="control">
              <input
              id="nephos-search"
              type="text"
              name="search"
              placeholder="Search a Product"
              />
              <span id="clear-search" role="button">
                 <i data-feather="x"></i>
              </span>
              <span className="bar"></span>
          </div>
          <span className="search-help">Type what you are looking for</span>
          </div>
      </div>

      {/* Background Caption */}
      <div className="background-caption">
        <h1>HoloXR</h1>
      </div>

      {/* Hero Caption */}
      <div className="hero-caption">
        <h1 className="main-title">Welcome to HoloRay</h1>
        <h2 className="main-subtitle">Revolutionizing Medical Education, Diagnosis, and Treatment Planning with Mixed-Reality Technology</h2>
        <a
          href="/dashboard"
          className="button big-button primary-button upper-button rounded raised">
          Start now
        </a>
      </div>

      {/* Fullscreen Slick Slider */}
      <div className="slider-wrapper">
        <div className="fullscreen-slick">
          <div
            className="full-slide has-background-image"
            style={{ backgroundImage: `url(${bg0})` }}
          ></div>
          <div
            className="full-slide has-background-image"
            style={{ backgroundImage: `url(${bg1})` }}
          ></div>
          <div
            className="full-slide has-background-image"
            style={{ backgroundImage: `url(${bg2})` }}
          ></div>
          <div
            className="full-slide has-background-image"
            style={{ backgroundImage: `url(${bg3})` }}
          ></div>
        </div>
      </div>
      
    </div>
  );
};

export default HomePage;
