import { Heading, Section } from "react-bulma-components";

import ContactList from "../../components/profile/ContactList";

export default function Contacts() {
    return (
        // <Section style={{ height: '100vh' }}>
        //     <Heading>Contacts</Heading>
        //     <Heading size={6}>
        //         Manage your contacts. Enter a <b>full</b> email address to
        //         search for a user.
        //     </Heading>

        //     <ContactList allowAdd allowRemove />
        // </Section>
        <>
        <div className="search-overlay"></div>   

        <div className="search-input-wrapper is-desktop is-hidden">
            <div className="field">
            <div className="control">
                <input
                id="nephos-search"
                type="text"
                name="search"
                placeholder="Search a Product"
                />
                <span id="clear-search" role="button">
                <i data-feather="x"></i>
                </span>
                <span className="bar"></span>
            </div>
            <span className="search-help">Type what you are looking for</span>
            </div>
        </div>
        <div className="section" style={{ height: '100vh' }}>

            <div className="container">

                <div className="columns account-header">
                    <div className="column main-column is-tablet-landscape-padded">

                        <div className="account-title">
                            <h2>Contacts</h2>

                            {/* <img
                            className="brand-filigrane"
                            src="assets/img/logo/logo-gray.svg"
                            alt=""
                            /> */}
                        </div>


                        <div className="tabs account-tabs">
                            <ul>
                                <li><a href="/dashboard">Dashboard</a></li>
                                <li><a href="/history">History</a></li>
                                <li className="is-active"><a href="/profile/contacts">Contacts</a></li>
                                <li><a href="/profile/subscription">Subscription</a></li>
                            </ul>
                        </div>


                        <ContactList allowAdd allowRemove />



                        </div>
                        </div>
            </div>
        </div>
        </>
    );
}
