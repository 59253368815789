import liveswitch from "fm.liveswitch";
import ChannelConnection from "./ChannelConnection";

export default class SfuConnection extends ChannelConnection {
    private upstreamConnection?: liveswitch.SfuUpstreamConnection;
    private downstreamConnections: {
        [key: string]: liveswitch.SfuDownstreamConnection;
    } = {};

    protected openConnection(): void {
        setTimeout(() => {
            this.channel.addOnRemoteUpstreamConnectionOpen(this.openDownstreamConnection.bind(this));
            this.openUpstreamConnection();
        }, 1500)

        setTimeout(() => {
            const remoteConnectionInfos = this.channel.getRemoteUpstreamConnectionInfos();
            console.log("Remote connection infos", remoteConnectionInfos)

            // Don't forget to add existing connections upon joining
            remoteConnectionInfos.forEach((remoteConnectionInfo) => {
                console.log("Remote connection info", remoteConnectionInfo)
                this.openDownstreamConnection(remoteConnectionInfo);
            });
        }, 1500)
    }

    protected closeConnection(): void {
        this.upstreamConnection?.close();
    }

    public toggleLocalVideo(): boolean {
        if (!this.upstreamConnection) {
            console.error("Upstream connection must be set before toggling local video.");
            return false;
        }

        const config: liveswitch.ConnectionConfig = this.upstreamConnection.getConfig();
        const enabled = !config.getLocalVideoMuted();

        config.setLocalVideoMuted(enabled);
        this.upstreamConnection.update(config);

        return enabled;
    }

    public override toggleLocalAudio(): boolean {
        if (!this.upstreamConnection) {
            console.error("Upstream connection must be set before toggling local audio.");
            return false;
        }

        const config: liveswitch.ConnectionConfig = this.upstreamConnection.getConfig();
        const enabled = !config.getLocalAudioMuted();

        config.setLocalAudioMuted(enabled);
        this.upstreamConnection.update(config);

        return enabled;
    }

    private openUpstreamConnection() {
        const audioStream = new liveswitch.AudioStream(this.localMedia);
        const videoStream = new liveswitch.VideoStream(this.localMedia);

        this.upstreamConnection = this.channel.createSfuUpstreamConnection(
            audioStream,
            videoStream
        );

        this.upstreamConnection.addOnStateChange((conn) => {
            console.log(
                `Upstream connection ${conn.getClientId()} is ${new liveswitch.ConnectionStateWrapper(
                    conn.getState()
                ).toString()}`
            );

            if (conn.getState() === liveswitch.ConnectionState.Failed) {
                this.openUpstreamConnection();
            }
        });

        this.upstreamConnection.open();
    }

    private openDownstreamConnection(
        remoteConnectionInfo: liveswitch.ConnectionInfo
    ) {
        const remoteMedia = new liveswitch.RemoteMedia(true, true);
        this.layoutManager.addRemoteMedia(remoteMedia);
        // var v = remoteMedia.getView();

        // v.style.width = '640px';
        // v.style.height = '480px';
        // v.style.position = "relative";

        // // Create a button element
        // const button = document.createElement('button');
        // button.innerText = 'Overlay Button';
        // button.className = 'clientoverlay_button__x-rvy is-link is-rounded button';

        // // Move the button to the top-left corner of the video frame
        // button.style.position = 'absolute';
        // button.style.top = '0';  // Top of the video frame
        // button.style.left = '0'; // Left of the video frame
        // button.style.zIndex = '10'; // Make sure it's on top
        // button.style.padding = '5px 10px';
        // button.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
        // button.style.border = 'none';
        // button.style.cursor = 'pointer';

        // // Add click event listener to the button
        // button.addEventListener('click', () => {
        //     console.log('Overlay button clicked!');
        //     alert('Button clicked!');
        // });

        // // Append the button to the video div
        // v.appendChild(button);

        const audioStream = new liveswitch.AudioStream(remoteMedia);
        const videoStream = new liveswitch.VideoStream(remoteMedia);

        const downstreamConnection = this.channel.createSfuDownstreamConnection(
            remoteConnectionInfo,
            audioStream,
            videoStream
        );

        this.downstreamConnections[downstreamConnection.getId()] = downstreamConnection;

        downstreamConnection.addOnStateChange((conn) => {
            console.log(
                `Downstream connection ${conn.getClientId()} is ${new liveswitch.ConnectionStateWrapper(
                    conn.getState()
                ).toString()}`
            );

            if (
                conn.getState() === liveswitch.ConnectionState.Closing ||
                conn.getState() === liveswitch.ConnectionState.Failing
            ) {
                delete this.downstreamConnections[conn.getId()];
                this.layoutManager.removeRemoteMedia(remoteMedia);
                remoteMedia.destroy();
            }
        });

        downstreamConnection.open();
    }
}
