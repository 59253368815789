import {
    arrayUnion,
    collection,
    doc,
    getDoc,
    getFirestore,
    onSnapshot,
    query,
    setDoc,
    where
} from "firebase/firestore";
import { functions, users } from "./firestore";

export type CallStatus =
    | "unknown"
    | "initiated"
    | "timed_out"
    | "answered"
    | "declined"
    | "canceled";

export type CallLog = {
    event: CallStatus;
    timestamp: number;
};

export class Call {
    id?: string;
    callerUid?: string;
    calleeUid?: string;
    callerEmail?: string;
    calleeEmail?: string;
    channel?: string;
    status: CallStatus = "unknown";
    platform: string = "web";
    lastUpdated: number = Date.now();
    callLogs: CallLog[] = [];

    static async listenForIncomingCalls(
        calleeUid: string,
        callback: (call: Call) => void
    ) {
        const db = getFirestore();

        const status: CallStatus = "initiated";

        const q = query(
            collection(db, "calls"),
            where("calleeUid", "==", calleeUid),
            where("status", "==", status)
        );

        const unsub = onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach(async (change) => {
                if (change.type === "added") {
                    const data = change.doc.data();

                    const call = new Call();
                    Object.assign(call, data);
                    call.id = change.doc.id;

                    const email = await users.getEmailByUid(call.callerUid!);
                    if (email) {
                        call.callerEmail = email;
                    }

                    callback(call);
                }
            });
        });

        return unsub;
    }

    static async getCallAsync(callId: string): Promise<Call> {
        const db = getFirestore();

        const callDoc = await getDoc(doc(db, "calls", callId));
        const data = callDoc.data();

        const call = new Call();
        Object.assign(call, data);
        call.id = callDoc.id;

        return call;
    }

    static async initiateCallAsync(
        userIdToken: string,
        calleeEmail: string,
        channel: string
    ): Promise<Call> {
        const response = await functions.callFunctionAsync(
            userIdToken,
            "initiateCall",
            {
                email: calleeEmail,
                channel,
                platform: "web"
            }
        );

        const callId = await response.text();
        const call = await Call.getCallAsync(callId);
        return call;
    }

    async updateStatusAsync(status: CallStatus) {
        const db = getFirestore();

        const timestamp = Date.now();

        const callLog: CallLog = {
            event: status,
            timestamp
        };

        await setDoc(
            doc(db, "calls", this.id!),
            {
                status,
                lastUpdated: timestamp,
                callLogs: arrayUnion(callLog)
            },
            { merge: true }
        );

        this.callLogs.push(callLog);
    }

    listenForChange(callback: () => void) {
        const db = getFirestore();

        const unsub = onSnapshot(doc(db, "calls", this.id!), (snapshot) => {
            const data = snapshot.data();
            Object.assign(this, data);
            callback();
        });

        return unsub;
    }
}
