import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { getAuth } from "firebase/auth";
import { setDoc, doc, getFirestore } from "firebase/firestore";
import { v4 } from "uuid";
import { Button, Card, Columns, Heading, Modal, Section } from "react-bulma-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpand } from '@fortawesome/free-solid-svg-icons';

export default function SignInOnHoloLens() {
    const [code, setCode] = useState("");
    const [expire, setExpire] = useState(new Date());
    const [fullscreen, showFullScreen] = useState(false);

    useEffect(() => {
        generateCode();
    }, []);

    async function generateCode() {
        const db = getFirestore();

        const code = v4();
        
        // Code will expire in 10 minutes by default
        var expire = new Date();
        expire.setMinutes(expire.getMinutes() + 10);
        setExpire(expire);
        
        const uid = getAuth().currentUser!.uid;
        
        await setDoc(doc(db, "users", uid), {
            signInCode: {
                code,
                expire
            }
        }, { merge: true });

        const qrCode = {
            code,
            uid
        };

        const json = JSON.stringify(qrCode);
        console.log(json);

        setCode(json);
    }

    return (
        <>
            <Section>
                <Heading>Signing in on HoloLens</Heading>
                <p>Scan the following QR code in the HoloLens application to automatically sign in on the device. <b>Note that each QR code is only valid for 10 minutes. Press the button again to generate a new code.</b></p>
                <Button rounded color="primary" onClick={generateCode} style={{ marginTop: 10 }}>Generate a new code</Button>
            </Section>

            {code &&
                <Section style={{ height: '100vh' }}>
                    <Card style={{ padding: 20 }}>
                        <Heading subtitle style={{ textAlign: "center" }}>Scan the QR code below from an HoloLens</Heading>
                        <Columns centered vCentered>
                            <Columns.Column size="half" style={{ display: "flex", justifyContent: "center" }}>
                                <QRCode value={code}></QRCode>
                            </Columns.Column>
                            <Columns.Column size="half" style={{ display: "flex", justifyContent: "center" }}>
                                <p>Expires at: {expire.toTimeString()}</p>
                            </Columns.Column>
                        </Columns>
                        <Button rounded color="link" fullwidth style={{ display: "flex", gap: 10, marginTop: 20 }} onClick={() => showFullScreen(true)}>
                            <FontAwesomeIcon icon={faExpand} />
                            Show in fullscreen
                        </Button>
                    </Card>
                </Section>
            }

            <Modal show={fullscreen} showClose={true} onClose={() => showFullScreen(false)} closeOnBlur={true} closeOnEsc={true}>
                <Modal.Content style={{ maxHeight: "100vh", padding: 16, width: "auto", background: "white" }}>
                    <QRCode id="svg" value={code} size={Math.min(window.innerWidth, window.innerHeight) - 32} />
                </Modal.Content>
            </Modal>
        </>
    );
}
