import {
    Box,
    Columns,
    Heading,
    Section
} from "react-bulma-components";
import ContactAll from "../components/profile/ContactAll";
import { v4 } from "uuid";
import {useUserContext} from "../contexts/UserProvider";
import { useState, useEffect } from "react";
import { showDangerToast } from "../js/utils/toasts";
import { Call } from "../js/firestore/Call";
import OutgoingCallModal from "../components/call/OutgoingCallModal";
import { useNavigate } from "react-router";
import CallHistory from "../components/dashboard/CallHistory";
import styles from '../styles/dashboard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
import feather from 'feather-icons';
import { Form } from "react-bulma-components";

export default function Dashboard() {
    const { user } = useUserContext();
    const [showCallModal, setShowCallModal] = useState(false);
    const [outgoingCall, setOutgoingCall] = useState<Call | null>(null);
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        feather.replace(); 
      }, []);

    const linkStyle = {
        color: isHovered ? '#d04a4a' : '#999',  // Change color on hover
        cursor: 'pointer'
    };

    function showError(message: string) {
        showDangerToast(message);
        setOutgoingCall(null);
        setShowCallModal(false);
    }

    async function initiateCall(email: string) {
        setShowCallModal(true);

        try {
            const channel = v4().split("-")[0];
            const idToken = await user!.getIdToken();

            const call = await Call.initiateCallAsync(idToken, email, channel);
            call.calleeEmail = email;
            setOutgoingCall(call);

            const unsub = call.listenForChange(() => {
                switch (call.status) {
                    case "timed_out":
                        showError("Call has timed out.");
                        break;
                    case "canceled":
                        showError("Call has been canceled");
                        break;
                    case "declined":
                        showError(`${call.calleeEmail} has declined the call.`);
                        break;
                    case "answered":
                        navigate(`/channel/${call.channel}`);
                        break;
                }

                if (call.status !== "initiated") {
                    unsub();
                }
            });
        } catch (error: any) {
            showError(error.message);
        }
    }

    async function cancelCall() {
        if (!outgoingCall) {
            return;
        }

        try {
            setShowCallModal(false);

            await outgoingCall.updateStatusAsync("canceled");
        } catch (error: any) {
            showError(error.message);
        } finally {
            setOutgoingCall(null);
        }
    }

    return (
        <>
            <div className="search-overlay"></div>   

            <div className="search-input-wrapper is-desktop is-hidden">
                <div className="field">
                <div className="control">
                    <input
                    id="nephos-search"
                    type="text"
                    name="search"
                    placeholder="Search a Product"
                    />
                    <span id="clear-search" role="button">
                    <i data-feather="x"></i>
                    </span>
                    <span className="bar"></span>
                </div>
                <span className="search-help">Type what you are looking for</span>
                </div>
            </div>
            <div className="section" style={{ height: '100vh', overflowY: 'scroll' }}>

                <div className="container">

                    <div className="columns account-header">
                        <div className="column main-column is-tablet-landscape-padded">

                            <div className="account-title">
                                <h2>Dashboard</h2>

                                {/* <img
                                className="brand-filigrane"
                                src="assets/img/logo/logo-grey.svg"
                                alt=""
                                /> */}
                            </div>


                            <div className="tabs account-tabs">
                                <ul>
                                    <li className="is-active"><a href="/dashboard">Dashboard</a></li>
                                    <li><a href="/history">History</a></li>
                                    <li><a href="/profile/contacts">Contacts</a></li>
                                    <li><a href="/profile/subscription">Subscription</a></li>
                                </ul>
                            </div>

                            <div id="wishlist-main" className="columns is-account-grid is-multiline">

                                <div className="column is-5">

                                    <div className="flat-card profile-card is-auto">
                                        <div className="card-body">
                                        <div className="profile-image">
                                            <img
                                            src="http://via.placeholder.com/250x250"
                                            alt=""
                                            />
                                        </div>
                                        <div className="username has-text-centered">
                                            <span id="full-name">{user?.email}</span>
                                            <small id="full-email">HoloUser</small>
                                        </div>
                                        </div>
                                        <div className="profile-footer has-text-centered">
                                        <span className="achievement-title">Total time</span>
                                        <div className="count">24/150</div>
                                        </div>
                                    </div>

                                </div>

                                <div className="column is-7">
                                    <div className="flat-card profile-info-card is-auto">

                                        <div className="card-title">
                                        <h3>Account details</h3>

                                        <div
                                            className="edit-account has-simple-popover popover-hidden-mobile"
                                            data-content="Edit Account"
                                            data-placement="top"
                                        >
                                            <a href="#"
                                            ><i className="feather-icons" data-feather="settings"></i
                                            ></a>
                                        </div>
                                        </div>

                                        <div className="card-body">
                                        <div className="columns">
                                            <div className="column is-6">
                                            <div className="info-block">
                                                <span className="label-text">First Name</span>
                                                <span id="account-first-name" className="label-value"
                                                > </span
                                                >
                                            </div>

                                            <div className="info-block">
                                                <span className="label-text">Email</span>
                                                <span id="account-email" className="label-value">{user?.email}</span>
                                            </div>
                                            </div>

                                            <div className="column is-6">
                                            <div className="info-block">
                                                <span className="label-text">Last Name</span>
                                                <span id="account-last-name" className="label-value"
                                                > </span
                                                >
                                            </div>

                                            <div className="info-block">
                                                <span className="label-text">Phone</span>
                                                <span id="account-phone-number" className="label-value"
                                                > </span
                                                >
                                            </div>
                                            </div>
                                        </div>
                                        </div>

                                    </div>
                                </div>

                                <ContactAll onCall={initiateCall} />

                            </div>
                        </div>
                    </div>

                </div>

            </div>

            {showCallModal && (
                <OutgoingCallModal
                    calleeEmail={outgoingCall?.calleeEmail}
                    onCancel={cancelCall}
                />
            )}

        </>
    );
}
