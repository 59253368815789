import { Container } from "react-bulma-components";
import { Route, Routes } from "react-router";
import AuthenticatedRoute from "../../components/AuthenticatedRoute";
import Contacts from "./Contacts";
import Subscription from "./Subscription";
import SignInOnHoloLens from "./SignInOnHoloLens";
import RequestPasswordReset from "./RequestPasswordReset";

export default function Profile() {
    return (
        <Container>
            <Routes>
                <Route path="/subscription" element={<AuthenticatedRoute Component={Subscription} />} />
                <Route path="/contacts" element={<AuthenticatedRoute Component={Contacts} />} />
                <Route path="/sign-in-on-hololens" element={<AuthenticatedRoute Component={SignInOnHoloLens} />} />
                <Route path="/request-password-reset" element={<AuthenticatedRoute Component={RequestPasswordReset} />} />
            </Routes>
        </Container>
    );
}
