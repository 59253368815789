import { collection, doc, getDoc, getDocs, getFirestore, query, where } from "firebase/firestore";
import firebaseConfig from "../../firebase_config.json";

const users = {
    async getEmailByUid(uid: string): Promise<string | null> {
        const db = getFirestore();
    
        const user = await getDoc(doc(db, "users", uid));
        if (!user.exists()) {
            return null;
        }
    
        const data: any = user.data();
        return data.email;
    },
    async getUidByEmail(email: string): Promise<string | null> {
        const db = getFirestore();
    
        const q = query(collection(db, "users"), where("email", "==", email));
        const querySnapshot = await getDocs(q);
    
        const uids: string[] = [];
        querySnapshot.forEach((doc) => {
            uids.push(doc.id);
        });
    
        if (uids.length > 1) {
            console.warn(
                `More than 1 contact detected with the same email ${email}`
            );
        }
    
        if (uids.length === 0) {
            return null;
        }
    
        return uids[0];
    }
};

const functions = {
    async callFunctionAsync(userIdToken: string, func: string, args: {}) {
        let url = `${firebaseConfig.functionsUrl}/${func}`;
    
        let i  = 0;
        for (const [key, value] of Object.entries(args)) {
            const op = i === 0 ? '?' : '&';
            url += `${op}${key}=${value}`;
            i++
        }
    
        return await fetch(url, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${userIdToken}`
            }
        });
    }
};

export { users, functions };
