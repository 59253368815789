import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { showDangerToast } from "../../js/utils/toasts";
import { Call, CallStatus } from "../../js/firestore/Call";
import { Modal, Button } from "react-bulma-components";
import CallAnimation from "./CallAnimation";
import {useUserContext} from "../../contexts/UserProvider";

export default function IncomingCall() {
    const { user, loading } = useUserContext();
    const [incomingCall, setIncomingCall] = useState<Call | null>(null);
    const navigate = useNavigate();

    function showError(message: string) {
        showDangerToast(message);
        setIncomingCall(null);
    }

    useEffect(() => {
        if (!user || loading) {
            return;
        }

        Call.listenForIncomingCalls(user.uid, (call) => {
            setIncomingCall(call);
        });
    }, [user, loading]);

    useEffect(() => {
        if (!incomingCall) {
            return;
        }

        const unsub = incomingCall.listenForChange(() => {
            switch (incomingCall.status) {
                case "timed_out":
                    showError("Call has timed out.");
                    break;
                case "canceled":
                    showError(`${incomingCall.callerEmail} has canceled the call.`);
                    break;
                case "declined":
                    showError(`Call has been declined.`);
                    break;
                case "answered":
                    navigate(`/channel/${incomingCall.channel}`);
                    setIncomingCall(null);
                    break;
            }
        });

        return () => unsub();
    }, [incomingCall, navigate]);

    async function tryAnswerCall(status: CallStatus) {
        if (!incomingCall) {
            return;
        }

        await incomingCall.updateStatusAsync(status);

        if (status === "answered") {
            navigate(`/channel/${incomingCall.channel}`);
        }
    }

    return (
        <Modal show={!!incomingCall} showClose={false}>
            <Modal.Card>
                <Modal.Card.Header showClose={false}>
                    <Modal.Card.Title>
                        Incoming call from {incomingCall?.callerEmail}
                    </Modal.Card.Title>
                </Modal.Card.Header>
                <Modal.Card.Body>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <CallAnimation shrink />
                    </div>
                </Modal.Card.Body>
                <Modal.Card.Footer>
                    <Button
                        rounded
                        color="success"
                        onClick={() => tryAnswerCall("answered")}>
                        Answer
                    </Button>
                    <Button
                        rounded
                        color="danger"
                        onClick={() => tryAnswerCall("declined")}>
                        Decline
                    </Button>
                </Modal.Card.Footer>
            </Modal.Card>
        </Modal>
    );
}
