import {
    Box,
    Columns,
    Heading,
    Section
} from "react-bulma-components";
import ContactAll from "../components/profile/ContactAll";
import { v4 } from "uuid";
import {useUserContext} from "../contexts/UserProvider";
import { useState, useEffect } from "react";
import { showDangerToast } from "../js/utils/toasts";
import { Call } from "../js/firestore/Call";
import OutgoingCallModal from "../components/call/OutgoingCallModal";
import { useNavigate } from "react-router";
import CallHistory from "../components/dashboard/CallHistory";
import styles from '../styles/dashboard.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faTrash } from '@fortawesome/free-solid-svg-icons';
import feather from 'feather-icons';
import { Form } from "react-bulma-components";

export default function Dashboard() {
    const { user } = useUserContext();
    const [showCallModal, setShowCallModal] = useState(false);
    const [outgoingCall, setOutgoingCall] = useState<Call | null>(null);
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        feather.replace(); 
      }, []);

    const linkStyle = {
        color: isHovered ? '#d04a4a' : '#999',  // Change color on hover
        cursor: 'pointer'
    };

    function showError(message: string) {
        showDangerToast(message);
        setOutgoingCall(null);
        setShowCallModal(false);
    }

    async function initiateCall(email: string) {
        setShowCallModal(true);

        try {
            const channel = v4().split("-")[0];
            const idToken = await user!.getIdToken();

            const call = await Call.initiateCallAsync(idToken, email, channel);
            call.calleeEmail = email;
            setOutgoingCall(call);

            const unsub = call.listenForChange(() => {
                switch (call.status) {
                    case "timed_out":
                        showError("Call has timed out.");
                        break;
                    case "canceled":
                        showError("Call has been canceled");
                        break;
                    case "declined":
                        showError(`${call.calleeEmail} has declined the call.`);
                        break;
                    case "answered":
                        navigate(`/channel/${call.channel}`);
                        break;
                }

                if (call.status !== "initiated") {
                    unsub();
                }
            });
        } catch (error: any) {
            showError(error.message);
        }
    }

    async function cancelCall() {
        if (!outgoingCall) {
            return;
        }

        try {
            setShowCallModal(false);

            await outgoingCall.updateStatusAsync("canceled");
        } catch (error: any) {
            showError(error.message);
        } finally {
            setOutgoingCall(null);
        }
    }

    return (
        <>
        <div className="search-overlay"></div>   

        <div className="search-input-wrapper is-desktop is-hidden">
            <div className="field">
            <div className="control">
                <input
                id="nephos-search"
                type="text"
                name="search"
                placeholder="Search a Product"
                />
                <span id="clear-search" role="button">
                <i data-feather="x"></i>
                </span>
                <span className="bar"></span>
            </div>
            <span className="search-help">Type what you are looking for</span>
            </div>
        </div>
            <div className="section" style={{ height: '100vh', overflowY: 'scroll' }}>

                <div className="container">

                    <div className="columns account-header">
                        <div className="column main-column is-tablet-landscape-padded">

                            <div className="account-title">
                                <h2>Call History</h2>
                            </div>


                            <div className="tabs account-tabs">
                                <ul>
                                    <li><a href="/dashboard">Dashboard</a></li>
                                    <li className="is-active"><a href="/history">History</a></li>
                                    <li><a href="/profile/contacts">Contacts</a></li>
                                    <li><a href="/profile/subscription">Subscription</a></li>
                                </ul>
                            </div>

                        
                            <CallHistory />



                        </div>
                    </div>

                </div>

            </div>

        </>
    );
}
