import { useEffect, useState } from "react";
import {
    doc,
    getDoc,
    getFirestore,
    onSnapshot,
    setDoc
} from "firebase/firestore";
import { Contact } from "./Contact";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";

export default function useContacts() {
    const [user, setUser] = useState<User | null>(null);
    const [contacts, setContacts] = useState<Contact[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const auth = getAuth();
        const db = getFirestore();

        let unsubscribeFromSnapshot = () => {};

        const fetchContacts = (userUID: string) => {
            try {
                const contactsRef = doc(db, "users", userUID);
                unsubscribeFromSnapshot = onSnapshot(
                    contactsRef,
                    async (snapshot) => {
                        const data: any = snapshot.data();
                        if (!data?.contacts) {
                            // User doesn't have contacts object in Firestore yet
                            setLoading(false);
                            return;
                        }

                        const uids = data.contacts as string[];

                        const emailPromises = uids.map(async (contact) => {
                            const contactDoc = await getDoc(
                                doc(db, "users", contact)
                            );

                            if (!contactDoc.exists()) {
                                // Contact uid couldn't be found in firestore
                                return {} as Contact;
                            }

                            const data: any = contactDoc.data();

                            return {
                                uid: contact,
                                email: data.email,
                                status: data.status
                            } as Contact;
                        });

                        const contactList = await Promise.all(emailPromises);
                        const filteredContacts = contactList.filter(
                            (x) => x.uid !== undefined
                        );
                        setContacts(filteredContacts);

                        setLoading(false);
                    },
                    (err) => {
                        setError(err.message);
                        setLoading(false);
                    }
                );
            } catch (err: any) {
                setError(err);
                setLoading(false);
            }
        };

        const unsubscribeFromAuth = onAuthStateChanged(auth, (user) => {
            setUser(user);
            if (user) {
                fetchContacts(user.uid);
            } else {
                setContacts([]);
                setLoading(false);
            }
        });

        return () => {
            unsubscribeFromAuth();
            unsubscribeFromSnapshot();
        };
    }, []);

    async function addContact(uid: string) {
        const db = getFirestore();

        // A user cannot add themselves
        if (user?.uid === uid) {
            return;
        }

        const contactsUids = contacts.map((x) => x.uid);
        contactsUids.push(uid);

        await setDoc(
            doc(db, "users", user!.uid),
            {
                contacts: contactsUids
            },
            { merge: true }
        );
    }

    async function removeContact(uid: string) {
        const db = getFirestore();

        const contactsUids = contacts
            .map((x) => x.uid)
            .filter((x) => x !== uid);
            
        await setDoc(
            doc(db, "users", user!.uid),
            {
                contacts: contactsUids
            },
            { merge: true }
        );
    }

    return { contacts, loading, error, addContact, removeContact };
}
