import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {useUserContext} from "../contexts/UserProvider";
import { Section, Progress } from "react-bulma-components";
import IncomingCall from "./call/IncomingCall";

export default function AuthenticatedRoute({ Component }: { Component: any }) {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, loading } = useUserContext();

    useEffect(() => {
        if (!loading && !user) {
            navigate({
                pathname: "/login",
                search: new URLSearchParams({
                    redirect: location.pathname
                }).toString()
            });
        }
    }, [user, loading, navigate, location.pathname]);

    if (loading) {
        return (
            <Section>
                <Progress color="info" size="small" />
            </Section>
        );
    }

    if (!user) {
        return <></>;
    }

    return (
        <>
            <Component />
            <IncomingCall />
        </>
    );
}
