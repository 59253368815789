import { toast, ToastType } from "bulma-toast";

export function showToast(message: string, color: ToastType) {
    toast({
        message: message,
        type: color,
        position: "top-center",
        dismissible: true,
        duration: 5000,
        pauseOnHover: true
    });
}

export function showDangerToast(message: string) {
    showToast(message, "is-danger");
}

export function showSuccessToast(message: string) {
    showToast(message, "is-success");
}
