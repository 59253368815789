import { Heading, Section } from "react-bulma-components";
import profpic from "../../assets/illustrations/profile.svg";

export default function Subscription() {
    return (
        // <Section style={{ height: '100vh' }}>
        //     <Heading>Subscription</Heading>
        //     <p>This section is a WIP. Come back later.</p>
        // </Section>
        <>
            <div className="search-overlay"></div>   

            <div className="search-input-wrapper is-desktop is-hidden">
                <div className="field">
                <div className="control">
                    <input
                    id="nephos-search"
                    type="text"
                    name="search"
                    placeholder="Search a Product"
                    />
                    <span id="clear-search" role="button">
                    <i data-feather="x"></i>
                    </span>
                    <span className="bar"></span>
                </div>
                <span className="search-help">Type what you are looking for</span>
                </div>
            </div>
            <div className="section" style={{ height: '100vh' }}>

                <div className="container">

                    <div className="columns account-header">
                        <div className="column main-column is-tablet-landscape-padded">

                            <div className="account-title">
                                <h2>Subscription</h2>

                                {/* <img
                                className="brand-filigrane"
                                src="assets/img/logo/logo-gray.svg"
                                alt=""
                                /> */}
                            </div>


                            <div className="tabs account-tabs">
                                <ul>
                                    <li><a href="/dashboard">Dashboard</a></li>
                                    <li><a href="/history">History</a></li>
                                    <li><a href="/profile/contacts">Contacts</a></li>
                                    <li className="is-active"><a href="/profile/subscription">Subscription</a></li>
                                </ul>
                            </div>

                            <div id="account-main-placeholder" className="main-placeholder">
                                <div className="placeholder-content">
                                <img src={profpic} alt="" />
                                <h3>Thank you for your interest</h3>
                                <p>
                                    This section is a WIP. Please come back later.
                                </p>
                                <div className="button-wrap">
                                    <a
                                    href="/dashboard"
                                    className="button big-button primary-button rounded raised">Dashboard</a>
                                </div>

                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
