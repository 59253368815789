import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../../styles/calling.module.css";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

export default function CallAnimation({ grow, shrink }: { grow?: boolean, shrink?: boolean }) {
    return (
        <div className={styles.wrapper}>
            <div className={grow ? styles.grow : shrink ? styles.shrink : ""}></div>
            <FontAwesomeIcon color="black" className={styles.icon} icon={faPhone} />
        </div>
    );
}
