import React, {useEffect} from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Columns } from "react-bulma-components";
import AuthenticatedRoute from "./AuthenticatedRoute";
import Dashboard from "../routes/Dashboard";
import Channel from "../routes/Channel";
import History from "../routes/History";
import LoginRegister from "../routes/LoginRegister";
import Profile from "../routes/profile/Profile";
import HoloRaySideMenu from "./HoloRaySideMenu";
import HomePage from "../routes/HomePage"; // Import the HomePage
import {useUserContext} from "../contexts/UserProvider";
import LoadingOverlay from "./Loading";

// Define the Layout component with TypeScript support
const Layout: React.FC = () => {
  const location = useLocation();
  const inChannel = location.pathname.includes('/channel');
  const { user, loading } = useUserContext();

  return (
    <>
    {loading && <LoadingOverlay />}
        {!inChannel && <HoloRaySideMenu/>}
        <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/dashboard" element={<AuthenticatedRoute Component={Dashboard} />} />
        <Route path="/history" element={<AuthenticatedRoute Component={History} />} />
        <Route path="/login" element={user ? <Navigate to="/dashboard" />: <LoginRegister/>} />
          <Route path="/profile/*" element={<AuthenticatedRoute Component={Profile} />} />
          <Route path="/channel/:id" element={<AuthenticatedRoute Component={Channel} />} />
          {/* Catch-all route to redirect unknown paths to the dashboard */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </>
  );
};

export default Layout;
