import liveswitch from "fm.liveswitch";
import { useEffect, useState } from "react";
import ChannelConnection from "../../js/connection/ChannelConnection";
import ClientOverlay from "./ClientOverlay";

type Props = {
    connection: ChannelConnection;
};

export default function MCULayoutOverlay({ connection }: Props) {
    const [overlays, setOverlays] = useState<OverlayData[]>([]);

    useEffect(() => {
        
        function resize({ videoDom, layout }: { videoDom: HTMLElement | null, layout: liveswitch.VideoLayout }): void {    
            let heightOffset = 0;
            let widthOffset = 0;
            let scaling = 1;

            // Get display area dimensions
            let dWidth = videoDom!.clientWidth;
            let dHeight = videoDom!.clientHeight;
            let dAspectRatio = dWidth / dHeight;
            const remoteUsers =connection.channel.getRemoteClientInfos();
            console.log("cooooooniiiiiii", connection.channel.getRemoteUpstreamConnectionInfos());

            const remoteParticipants = connection.channel.getRemoteUpstreamConnectionInfos();
            const remoteViewIDs = connection.layoutManager.getRemoteViewIds();
            // Get MCU video content dimensions
            // let vWidth = layout.getWidth();
            // let vHeight = layout.getHeight();
            // let vAspectRatio = vWidth / vHeight;

            // if (vAspectRatio > dAspectRatio) {
            //     scaling = dWidth / vWidth;
            //     heightOffset = (dHeight - vHeight * scaling) / 2;
            // } else {
            //     scaling = dHeight / vHeight;
            //     widthOffset = (dWidth - vWidth * scaling) / 2;
            // }

            const data: OverlayData[] = [];
            

            // Do MCU name overlays
            // layout.getRegions().forEach(region => {
                
            //     let frame = region.getFrame();
            //     let bounds = region.getBounds();
            //     const top = heightOffset + scaling * (frame.getY() + bounds.getY());
            //     const left = widthOffset + scaling * (frame.getX() + bounds.getX());
            //     const right = scaling * (frame.getX() + bounds.getX())+10;
            //     const bottom = scaling * (frame.getY() + bounds.getY())+10;

            //     const connectionId = region.getConnectionId();
            //     const userId = region.getUserId();
            //     const userAlias = region.getUserAlias();
            //     const roles = region.getClientRoles();

            //     data.push({ connectionId, userId, userAlias, roles, top, left, right, bottom });
            // });
            var key=0;
            // const frame={top: 0, left: 0 , width: 0, height: 0};
            remoteParticipants.forEach(remoteUser => {
                const participantId = remoteViewIDs[key];
                const frame = getRemoteViewPosition(participantId);
                const connectionId = remoteUser.getRemoteConnectionId();
                const userId = remoteUser.getUserId();
                const userAlias = remoteUser.getUserAlias();
                const roles = remoteUser.getClientRoles();
                const top = frame?.top;//heightOffset + scaling * (frame.getY() + bounds.getY());
                const left = frame?.left;//widthOffset + scaling * (frame.getX() + bounds.getX());
                const right = frame?.right;//scaling * (frame.getX() + bounds.getX())+10;
                const bottom = frame?.bottom;//scaling * (frame.getY() + bounds.getY())+10;
                data.push({connectionId, userId, userAlias, roles, top, left, right, bottom });
                key=key+1;
            });

            setOverlays(data);
            console.log("dataaaaaaaaaa:", data);

        }

        connection.events.addListener("onvideolayout", resize);

        return () => {
            connection.events.removeListener("onvideolayout", resize);
        }

    }, [connection.events]);

    
    function getRemoteViewPosition(participantId: string): DOMRect | undefined {
        const layoutManager= connection.layoutManager;
        if (layoutManager) {
            const remoteView = layoutManager.getRemoteView(participantId);
            const rect = remoteView.getBoundingClientRect();
            console.log(`Remote View - top: ${rect.top}, left: ${rect.left}, Width: ${rect.width}, Height: ${rect.height}`);

            return rect;
        }
    }

    function onScreenshotRequest(holoLensId: string): void {
        connection.messager.requestScreenshot(holoLensId).then((videoFrameData: VideoFrameData) => {
            connection.events.notify("onscreenshot", { userId: holoLensId, videoFrameData });
        }).catch(ex => console.log(ex));
    }

    function onUploadClick(holoLensId: string): void {
        connection.events.notify("onscreenshot", { userId: holoLensId, videoFrameData: null });
    }

    return (
        <div style={{ zIndex: 1 }}>
            {overlays.map(o => <ClientOverlay key={o.connectionId} overlay={o} onScreenshotClick={onScreenshotRequest} onUploadClick={onUploadClick} />)}
        </div>
    );
}

export interface OverlayData {
    connectionId: string;
    userId: string;
    userAlias: string;
    roles: string[] | undefined;
    top: number | undefined;
    left: number | undefined;
    right: number | undefined;
    bottom: number | undefined;
}