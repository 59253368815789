// import 'bulma/css/bulma.min.css';
// import '@creativebulma/bulma-tooltip/dist/bulma-tooltip.min.css';
// import './index.css' // Has to be imported after bulma to override
import './styles/app.css'; // Add custom CSS here if needed
import './styles/main.css'; // Add custom CSS here if needed

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { initializeApp } from "firebase/app";
import Layout from './components/Layout';
import firebaseConfig from './firebase_config.json';
import UserProvider from './contexts/UserProvider';
function throwErrorForMissingVariable(value: string) {
  throw new Error(`The environment variable "${value}" could not be found. Please refer to README.md on how to configure these variables correctly.`);
}

if (!process.env.REACT_APP_LIVESWITCH_APPLICATION_ID) {
  throwErrorForMissingVariable("REACT_APP_LIVESWITCH_APPLICATION_ID");
}
if (!process.env.REACT_APP_LIVESWITCH_GATEWAY_URL) {
  throwErrorForMissingVariable("REACT_APP_LIVESWITCH_GATEWAY_URL");
}
if (!process.env.REACT_APP_LIVESWITCH_SHARED_SECRET) {
  throwErrorForMissingVariable("REACT_APP_LIVESWITCH_SHARED_SECRET");
}

initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
      <UserProvider>
          <Router>
              <Layout />
          </Router>
      </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
