import liveswitch from "fm.liveswitch";
import ChannelConnectionEvents from "./ChannelConnectionEvents";
import Screenshare from "./Screenshare";
import Messager from "./Messager";
import ClientOverlay from '../../components/channel/ClientOverlay'; // Adjust import path as necessary
import ReactDOM from 'react-dom';

export default abstract class ChannelConnection {
    private videoConfig = new liveswitch.VideoConfig(640, 480, 30);
    public localMedia = new liveswitch.LocalMedia(true, this.videoConfig, false);

    public layoutManager!: liveswitch.DomLayoutManager;
    private videoDom!: HTMLElement | null;
    private videoLayout!: liveswitch.VideoLayout;

    private client!: liveswitch.Client;
    public channel!: liveswitch.Channel;
    
    public screenshare!: Screenshare;
    public messager!: Messager;
    public events: ChannelConnectionEvents = new ChannelConnectionEvents();

    private initializeLayoutManager() {
        this.videoDom = document.getElementById("video") as HTMLElement;
        if (!this.videoDom) {
            this.logError("Couldn't find Video element in page");
            return;
        }
        
        this.layoutManager = new liveswitch.DomLayoutManager(this.videoDom);

        this.layoutManager.addOnLayout(_ => this.events.notify("onvideolayout", { videoDom: this.videoDom, layout: this.videoLayout }));
        // this.layoutManager.addOnLayout(() => {

        //     this.overlayClientOverlayOnRemoteConnections();
        // });
        this.layoutManager.setLocalView(this.localMedia.getView());
        
    }

    // private setLocalViewPosition(top: number, left: number) {
    //     if (this.layoutManager) {
    //         const localView = this.layoutManager.getLocalView();
    //         if (localView) {
    //             localView.style.position = "absolute"; // Ensure the view is absolutely positioned
    //             localView.style.top = `${top}px`; // Set the new top position
    //             localView.style.left = `${left}px`; // Set the new left position

    //             console.log(`Local View updated - Top: ${top}, Left: ${left}`);
    //         } else {
    //             console.log("Local view not found.");
    //         }
    //     }
    // }

    // private getLocalViewPosition() {
    //     if (this.layoutManager) {
    //         const localView = this.layoutManager.getLocalView();
    //         if (localView) {
    //             const rect = localView.getBoundingClientRect();
    //             console.log(`Local View - X: ${rect.x}, Y: ${rect.y}, Width: ${rect.width}, Height: ${rect.height}`);
    //             // You can now use these coordinates to do something in your code
    //         }
    //     }
    // }

    // private overlayClientOverlayOnRemoteConnections() {
    //     // Assuming you have a channel object and it has active connections
    //     if (this.channel && this.layoutManager) {
    //         const connections = this.channel.getConnections(); // Fetch remote connections
    //         const remoteUsers =this.channel.getRemoteClientInfos();
    //         const remoteViewIDs = this.layoutManager.getRemoteViewIds();
    //         console.log("remote::::::::::User", remoteUsers);
    //         //console.log("remote::::::::::View", remoteViewIDs);

    //         var id = 0;
    //         remoteViewIDs.forEach(remoteViewID => {
    //             const UserID=remoteUsers[id];
    //             this.getRemoteViewPosition(remoteViewID, "UserID");
    //             id= id+1;
    //         });
    //     }
    // }

    // private getRemoteViewPosition(participantId: string, UserId: string) {
    //     if (this.layoutManager) {
    //         const remoteView = this.layoutManager.getRemoteView(participantId);
    //         var remoteUser = this.getUserAliasById(participantId);
    //         console.log("----remoteUser-----", remoteUser);
    //         if (remoteView) {
    //             const rect = remoteView.getBoundingClientRect();
    //             console.log(`Remote View - top: ${rect.top}, left: ${rect.left}, Width: ${rect.width}, Height: ${rect.height}`);
    //             // You can now use these coordinates to do something in your code

    //             // Create a button element
    //             const button = document.createElement('button');
    //             button.innerText = `OverLay`;
    //             button.className = 'clientoverlay_button__x-rvy is-link is-rounded button';
                
    //             // Move the button to the top-left corner of the video frame
    //             button.style.position = 'absolute';
    //             button.style.top = '0';  // Top of the video frame
    //             button.style.left = '0'; // Left of the video frame
    //             button.style.zIndex = '10'; // Make sure it's on top
    //             button.style.padding = '5px 10px';
    //             button.style.backgroundColor = 'rgba(255, 255, 255, 0.7)';
    //             button.style.border = 'none';
    //             button.style.cursor = 'pointer';

    //             // Add click event listener to the button
    //             button.addEventListener('click', () => {
    //                 console.log('Overlay button clicked!');
    //                 alert('Button clicked!');
    //             });

    //         }
    //     }
    // }

    public connectToChannel(channelId: string, userAlias: string) {
        const gatewayUrl = process.env.REACT_APP_LIVESWITCH_GATEWAY_URL!;
        const applicationId = process.env.REACT_APP_LIVESWITCH_APPLICATION_ID!;
        const sharedSecret = process.env.REACT_APP_LIVESWITCH_SHARED_SECRET!;

        if (!channelId) {
            console.log("Channel ID to join cannot be empty");
            return;
        }

        console.log(`Attempting to join channel ${channelId}`);

        this.client = new liveswitch.Client(gatewayUrl, applicationId);
        this.client.setUserAlias(userAlias);

        let token = liveswitch.Token.generateClientRegisterToken(
            applicationId,
            this.client.getUserId(),
            this.client.getDeviceId(),
            this.client.getId(),
            this.client.getRoles(),
            [new liveswitch.ChannelClaim(channelId)],
            sharedSecret
        );

        this.client.register(token).then((_) => {
            console.log(`Client ${this.client.getId()} registered`);

            token = liveswitch.Token.generateClientJoinToken(this.client, new liveswitch.ChannelClaim(channelId), sharedSecret);

            this.client.join(channelId, token).then((channel) => {
                console.log(`Successfully joined channel ${channel.getId()} as ${this.client.getUserAlias()}`);
                this.onChannelJoined(channel);
            }).fail((ex) => {
                this.logError("Failed to join channel: " + ex.message)
            });

        }).fail((ex) => {
            this.logError("Registration failed: " + ex.message)
        });
    }

    private onChannelJoined(channel: liveswitch.Channel) {
        this.initializeLayoutManager();

        this.channel = channel;
        // Start the screenshare manager
        this.screenshare = new Screenshare(this.channel, this.events, this.logError);

        // Start the message manager
        this.messager = new Messager(this.channel, this.events, this.logError);

        this.openConnection();

        this.localMedia.start().then((_) => {
            console.log("Local media started");
        }).fail((ex) => {
            this.logError(`${ex.message}. Make sure your webcam is plugged in and visible by your operating system.`)
        });
    }

    protected abstract openConnection(): void;
    protected abstract closeConnection(): void;
    public abstract toggleLocalVideo(): boolean;
    public abstract toggleLocalAudio(): boolean;

    public leaveChannel(): void {
        if (this.client) {
            this.client.leave(this.client.getChannels()[0].getId()).then(_ => {
                console.log("Left channel");

                this.client.unregister().then(_ => {
                    console.log("Unregistered");
                }).fail(ex => this.logError(ex.message));

            }).fail(ex => this.logError(ex.message));
        }

        if (this.localMedia) {
            this.localMedia.stop().then(_ => {
                console.log("Local media stopped");
                this.localMedia.destroy();
            }).fail(ex => this.logError(`Failed to stop local media: ${ex.message}`));
        }

        this.closeConnection();

        if (this.screenshare) {
            this.screenshare.close();
        }
    }

    public getUserAliasById(userId: string | undefined): string | undefined {
        if (this.channel === undefined) {
            return undefined;
        }
        return this.channel.getRemoteClientInfos().find(x => x.getUserId() === userId)?.getUserAlias();
    }

    public isMe(userId: string): boolean {
        return this.client.getUserId() === userId;
    }

    private logError(error: string): void {
        console.log(error);
        this.events.notify("onerror", error);
    }
}
