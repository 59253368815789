import styles from '../styles/channel.module.css';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Button } from 'react-bulma-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus, faVideo, faMicrophone, faPhoneSlash, faPen } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'bulma-toast';
import Div100vh from 'react-div-100vh'
import LeaveChannelModal from '../components/channel/LeaveChannelModal';
import ChatBox from '../components/channel/ChatBox';
import SfuConnection from '../js/connection/SfuConnection';
import ScreenshareToggle from '../components/channel/ScreenshareToggle';
import PainterroUtils from '../components/channel/PainterroUtils';
import {useUserContext} from '../contexts/UserProvider';
import '../assets/css/ChannelCustomStyle.css';
import MCULayoutOverlay from '../components/channel/MCULayoutOverlay';

export default function Channel() {
    let [connection] = useState(new SfuConnection());
    const [isLocalVideoMuted, setLocalVideoMuted] = useState<boolean>(false);
    const [isLocalAudioMuted, setLocalAudioMuted] = useState<boolean>(false);
    // const navigation = useRef(useNavigate());
    const navigate = useNavigate();
    const channelId = useRef(useParams().id);
    const { user } = useUserContext();

    useEffect(() => {
        if (!user) {
            return;
        }

        function onError(error: string): void {
            toast({ message: error, type: 'is-danger', position: 'top-center', dismissible: true, duration: 20000, pauseOnHover: true });
        }

        function onScreenshot({ userId, videoFrameData }: { userId: string, videoFrameData: VideoFrameData }): void {
            const painterroUtils = new PainterroUtils();

            painterroUtils.show(videoFrameData.Data, (base64Image, annotations) => {
                const newFrameData: VideoFrameData = videoFrameData;
                // Remove the header since we only want to send the data
                newFrameData.Data = base64Image.split(",", 2)[1];

                const imageData: ImageAnnotationData = {
                    VideoFrameData: newFrameData,
                    Annotations: annotations
                };

                connection.messager.sendImage(userId, imageData).then(_ => {
                    toast({ message: "Image sent!", type: 'is-success', position: 'top-center' });
                }).fail(ex => onError(ex.message));
            });
        }

        if(channelId.current) {
            connection.connectToChannel(channelId.current, user.email!);
            connection.events.addListener("onerror", onError);
            connection.events.addListener("onscreenshot", onScreenshot);
            //console.log("Connection:", connection);
        }

        return () => {
            connection.events.removeListener("onerror", onError);
            connection.events.removeListener("onscreenshot", onScreenshot);
            connection.leaveChannel();
        }
    }, [connection]);

    return (
        <Div100vh className={styles.video} id="video">
            <div id={styles.footer} className="buttons is-centered">
                <Button color="link" rounded data-tooltip="Invite people" onClick={onInviteClick}>
                    <FontAwesomeIcon icon={faUserPlus} />
                </Button>
                <Button color={isLocalVideoMuted ? "" : "primary"} rounded data-tooltip="Toggle local video" onClick={onToggleVideoClick}>
                    <FontAwesomeIcon icon={faVideo} />
                </Button>
                <Button color={isLocalAudioMuted ? "" : "primary"} rounded data-tooltip="Toggle local audio" onClick={onToggleAudioClick}>
                    <FontAwesomeIcon icon={faMicrophone} />
                </Button>
                <ScreenshareToggle connection={connection} onClick={onToggleScreenshareClick} />
                <Button className={"endcall"} id={styles.endcall} style={{marginLeft: "8em"}} color="danger" rounded data-tooltip="Leave this channel" onClick={onLeaveCallClick}>
                   <span style={{marginRight: "15px"}}>End Call</span><FontAwesomeIcon icon={faPhoneSlash} />
                </Button>
            </div>

            <MCULayoutOverlay connection={connection} />

            <ChatBox connection={connection} />

            <LeaveChannelModal title="Leave the channel?" onSuccess={() => navigate("/dashboard")} onCancel={onLeaveCallClick} />
        </Div100vh>
    )

    function onScreenshotRequest(): void {
        var holoLensId = "";
        connection.messager.requestScreenshot(holoLensId).then((videoFrameData: VideoFrameData) => {
            connection.events.notify("onscreenshot", { userId: holoLensId, videoFrameData });
        }).catch(ex => console.log(ex));
    }

    function onInviteClick() {
        navigator.clipboard.writeText(window.location.href);

        toast({ message: "Invite link copied to clipboard!", type: 'is-success', position: 'top-center' });
    }

    function onToggleVideoClick() {
        setLocalVideoMuted(connection.toggleLocalVideo());
    }

    function onToggleAudioClick() {
        setLocalAudioMuted(connection.toggleLocalAudio());
    }

    function onToggleScreenshareClick() {
        connection.screenshare.toggleScreenshare();
    }

    function onLeaveCallClick() {
        const confirmLeave = document.getElementById("confirmLeave");
        if (confirmLeave) {
            confirmLeave.classList.toggle('is-active');
        }
    }
}
