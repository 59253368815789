import { useRef, useState, useEffect } from "react";
import { Button, Card, Form } from "react-bulma-components";
import useContacts from "../../js/firestore/useContacts";
import { Contact } from "../../js/firestore/Contact";
import ContactItem from "../../components/profile/ContactItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import {useUserContext} from "../../contexts/UserProvider";
import { users } from "../../js/firestore/firestore";
import { showDangerToast, showSuccessToast } from "../../js/utils/toasts";
import feather from 'feather-icons';

export default function ContactList({
    allowAdd,
    allowRemove,
    onCall
}: {
    allowAdd?: boolean;
    allowRemove?: boolean;
    onCall?: (calleeEmail: string) => void;
}) {
    const { user } = useUserContext();
    const { contacts, loading, addContact, removeContact } = useContacts();
    const [search, setSearch] = useState("");
    const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
    const inputRef = useRef<any>();

    useEffect(() => {
        feather.replace(); 
      }, []);

    function onInputChanged(value: string) {
        setSearch(value);
        setFilteredContacts(
            contacts.filter((contact) => isSubsequence(value, contact.email))
        );
    }

    async function tryAddContact(email: string) {
        const contactFound = await users.getUidByEmail(email);
        if (!contactFound) {
            showDangerToast(`${email} was not found`);
            return;
        }

        await addContact(contactFound);
        showSuccessToast(`${email} was added successfully`);

        // Reset the input field
        inputRef.current.value = "";
        onInputChanged("");
    }

    async function tryRemoveContact(contact: Contact) {
        await removeContact(contact.uid);
        showSuccessToast(`${contact.email} was removed successfully`);
    }

    function renderContact(contact: Contact, isKnown: boolean) {
        return (
            <ContactItem
                key={contact.uid}
                style={{ width: "100%" }}
                contact={contact}>
                <Button.Group>
                    {!!onCall && (
                        // <Button
                        //     rounded
                        //     size="small"
                        //     color="success"
                        //     style={{ marginRight: 10 }}
                        //     onClick={() => onCall(contact.email)}>
                        //     <FontAwesomeIcon
                        //         style={{ marginRight: 10 }}
                        //         icon={faPhone}
                        //     />
                        //     Call
                        // </Button>
                        <a className="remove-button" onClick={() => onCall(contact.email)}>
                            <i data-feather="phone"></i>
                        </a>
                    )}
                    {allowRemove && isKnown && (
                        // <Button
                        //     rounded
                        //     size="small"
                        //     color="danger"
                        //     onClick={() => tryRemoveContact(contact)}>
                        //     <FontAwesomeIcon
                        //         style={{ marginRight: 10 }}
                        //         icon={faTrash}
                        //     />
                        //     Remove
                        // </Button>
                        <a className="remove-button" onClick={() => tryRemoveContact(contact)}>
                            <i data-feather="trash-2"></i>
                        </a>
                    )}
                    {allowAdd && !isKnown && (
                        // <Button
                        //     rounded
                        //     size="small"
                        //     color="info"
                        //     onClick={() => tryAddContact(contact.email)}>
                        //     <FontAwesomeIcon
                        //         style={{ marginRight: 10 }}
                        //         icon={faPlus}
                        //     />
                        //     Add
                        // </Button>
                        <div className="card-title">
                                <div className="edit-account">
                                <a
                                    onClick={() => tryAddContact(contact.email)}
                                    className="modal-trigger has-simple-popover">
                                    
                                    <i className="feather-icons" data-feather="plus"></i>
                                </a>
                            </div>
                        </div>
                    )}
                </Button.Group>
            </ContactItem>
        );
    }

    function renderContacts(isSearch: boolean) {
        if (loading) {
            // Contacts are still loading
            return <></>;
        }
        
        if (isSearch && search && search !== user?.email) {
            const isUserSearchingForEmail =
                search.includes("@") && search.includes(".");

            if (filteredContacts.length === 0) {
                if (isUserSearchingForEmail) {
                    const contactToFind: Contact = {
                        email: search,
                        uid: search
                    };
                    // User is searching for a full email, to add or call someone directly
                    return renderContact(contactToFind, false);
                }

                // No known contacts found while searching
                return <p style={{padding: "10px"}}>No contact with email "{search}" was found.</p>;
            }
            return filteredContacts.map((contact) =>
                renderContact(contact, true)
            );
        }

        
        if (contacts.length === 0) {
            // User doesn't have any contact
            return <p style={{padding: "10px"}}>Your contact list is empty.</p>;
        }
        if(!isSearch){
            // User has contacts
            return contacts.map((contact) => renderContact(contact, true));
        }
    }

    function isSubsequence(query: string, target: string) {
        let index = 0;
        query = query.toLowerCase();
        target = target.toLowerCase();

        for (const char of target) {
            if (index === query.length) {
                break;
            }

            if (char === query[index]) {
                index++;
            }
        }
        return index === query.length;
    }

    return (
        // <Card>
        //     <Card.Content>
        //         <Form.Control fullwidth>
        //             <Form.Input
        //                 domRef={inputRef}
        //                 onChange={(e) => onInputChanged(e.target.value)}
        //                 placeholder="Search"
        //                 type="text"
        //             />
        //         </Form.Control>
        //         <hr />
        //         <div style={{ overflowY: "auto", maxHeight: 400 }}>
        //             {renderContacts()}
        //         </div>
        //     </Card.Content>
        // </Card>
        <div id="account-main" className="columns is-account-grid is-multiline">
            <div className="column is-4">
                <div className="flat-card is-auto menu-card">
                    <div className="card-title">
                        <Form.Control fullwidth style={{width: "100%"}}>
                            <Form.Input
                                domRef={inputRef}
                                onChange={(e) => onInputChanged(e.target.value)}
                                placeholder="Search"
                                type="text"
                            />
                        </Form.Control>
                    </div>
                    <div className="flat-card wishlist-card is-auto">
                        <ul className="wishlist">

                            {renderContacts(true)}

                        </ul>
                    </div>
                </div>
            </div>
            <div className="column is-8">
                <div className="flat-card wishlist-card is-auto">

                    <ul className="wishlist">

                        {renderContacts(false)}

                    </ul>
                </div>
            </div>
        </div>
    );
}
