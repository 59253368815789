import React from 'react';
import { useLocation, useNavigate } from "react-router";
import { useUserContext } from "../contexts/UserProvider";
import userAvatar from "../assets/img/icons/userAvatar.png";


export default function MainQuickView() {
    const navigate = useNavigate();
    const location = useLocation();
    const { logOut } = useUserContext();
    const { user } = useUserContext();

    const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        logOut().then(() => navigate("/login"));
    }

    return (
    <>
        <div className="shop-quickview has-background-image" data-background="assets/img/bg/sidebar.jpeg">
            <div className="inner">

                <div className="quickview-header">
                <h2>HoloXR</h2>
                {/* <span id="close-shop-sidebar"><i data-feather="x"></i></span> */}
                </div>

                <ul className="shop-menu">
                <li>
                    <a href="/dashboard">
                    <span>Dashboard</span>
                    <i data-feather="grid"></i>
                    </a>
                </li>
                <li>
                    <a href="/profile/contacts">
                    <span>My Contacts</span>
                    <i data-feather="user"></i>
                    </a>
                </li>
                <li>
                    <a href="/profile/sign-in-on-hololens">
                    <span>Sign In On HoloLens</span>
                    <i data-feather="box"></i>
                    </a>
                </li>
                
                <li>
                    <a href="/profile/subscription">
                    <span>Subscription</span>
                    <i data-feather="credit-card"></i>
                    </a>
                </li>
                <li>
                    <a onClick={handleLogout}>
                    <span>Logout</span>
                    <i data-feather="log-out"></i>
                    </a>
                </li>
                </ul>

                <ul className="user-profile">
                <li>
                    <a onClick={(e) => e.preventDefault()}>
                    <img
                        id="quickview-avatar"
                        src={userAvatar}
                        alt=""
                    />
                    <span className="user">
                        <span id="quickview-username"> {user?.email} </span>
                        <span id="quickview-cart-count"
                        ><var>V 1.0</var>-<small>2024</small></span>
                    </span>
                    </a>
                </li>
                </ul>
            </div>
        </div>
    </>
  );
}