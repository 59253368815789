import React, {useEffect} from 'react';
import holorayLogo from "../assets/img/logo/icon-192.png";
import CategoryQuickView from './CategoryQuickView';
import MainQuickView from './MainQuickView';
import { useLocation, useNavigate } from "react-router";
import { useUserContext } from "../contexts/UserProvider";
import feather from 'feather-icons';


export default function HoloRaySideMenu() {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, logOut } = useUserContext();

    const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        logOut().then(() => navigate("/login"));
    }
    
    useEffect(() => {
        feather.replace(); 
    }, [user])

    return (
    <>
        <div className="pageloader"></div>
        <nav
            className="navbar mobile-navbar is-hidden-desktop is-hidden-tablet"
            aria-label="main navigation"
            >
            <div className="navbar-brand">
                <a className="navbar-item" href="home.html">
                    <img src={holorayLogo} alt="" />
                </a>
                <a
                id="sidebar-mode"
                className="navbar-item is-icon is-sidebar-toggler"
                onClick={(e) => e.preventDefault()}
                >
                <i data-feather="sidebar"></i>
                </a>

            </div>
        </nav>

        <div className="main-sidebar">
            <div className="sidebar-brand">
                <a  href="/"><img src={holorayLogo} alt="" /></a>
            </div>
            <div className="sidebar-inner">
                <ul className="icon-menu">
                    <li>
                        <a onClick={(e) => e.preventDefault()} id="open-shop"><i data-feather="home"></i></a>
                    </li>

                    <li>
                        <a onClick={(e) => e.preventDefault()} id="open-search">
                            <i data-feather="search"></i>
                        </a>
                        <a onClick={(e) => e.preventDefault()} id="close-search" className="is-hidden is-inactive">
                            <i data-feather="x"></i>
                        </a>
                    </li>


                </ul>
                
                <ul className="bottom-menu">
                    <li className="is-hidden-mobile">
                        {!user ? (
                            ""// <a id="login-link" href="/login"><i data-feather="user"></i></a>
                        ) : (
                            <a onClick={handleLogout} id="logout-link">
                                <i data-feather="log-out"></i>
                            </a>
                        )}
                    </li>
                    <li id="fold-link" className="fold-link">
                        <a><i data-feather="arrow-left"></i></a>
                    </li>

                </ul>
            </div>
        </div>

        <div id="quickview-trigger" className="menu-fab is-hidden-mobile">
            <a className="hamburger-btn" onClick={(e) => e.preventDefault()}>
                <span className="menu-toggle">
                <span className="icon-box-toggle">
                    <span className="rotate">
                        <i className="icon-line-top"></i>
                        <i className="icon-line-center"></i>
                        <i className="icon-line-bottom"></i>
                    </span>
                </span>
                </span>
            </a>
        </div>

        <CategoryQuickView/>

        {user && <MainQuickView/>}

    </>
  );
};


