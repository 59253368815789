import { useEffect, useState } from "react";
import { Call } from "../../js/firestore/Call";
import {
    and,
    collection,
    getDocs,
    getFirestore,
    limit,
    or,
    orderBy,
    query,
    where
} from "firebase/firestore";
import { Button, Columns } from "react-bulma-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendar,
    faPhone,
    faTv,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import {useUserContext} from "../../contexts/UserProvider";
import { users } from "../../js/firestore/firestore";

export default function CallHistory() {
    const { user } = useUserContext();
    const [calls, setCalls] = useState<Call[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            return;
        }

        (async () => {
            const db = getFirestore();

            const q = query(
                collection(db, "calls"),
                and(
                    where("status", "!=", "initiated"),
                    or(
                        where("callerUid", "==", user.uid),
                        where("calleeUid", "==", user.uid)
                    )
                ),
                orderBy("lastUpdated", "desc"),
                limit(15)
            );

            const snapshot = await getDocs(q);

            const calls: Call[] = [];
            snapshot.forEach((doc) => {
                const data = doc.data();
                const call = new Call();
                Object.assign(call, data);
                call.id = doc.id;

                calls.push(call);
            });

            // Create a small cache of already fetched emails to reduce calls to firestore
            const emailCache = new Map<string, string>();

            // Set the email of each caller
            for (const call of calls) {
                if (call.callerUid === user.uid) {
                    call.callerEmail = "You";
                } else {

                    if (emailCache.has(call.callerUid!)) {
                        call.callerEmail = emailCache.get(call.callerUid!);
                    }
                    else {
                        call.callerEmail = (await users.getEmailByUid(
                            call.callerUid!
                        ))!;
                        emailCache.set(call.callerUid!, call.callerEmail);
                    }
                }
            }

            setCalls(calls);
        })();
    }, [user]);

    return (
        <div className="column is-12">
            <div className="flex-table">
                    <div className="flex-table-header">
                      <span className="product"><span>From</span></span>
                      <span className="date"><FontAwesomeIcon
                                        style={{ marginRight: 10 }}
                                        icon={faCalendar}
                                    />Date</span>
                      <span className="status"><FontAwesomeIcon
                                        style={{ marginRight: 10 }}
                                        icon={faPhone}
                                    />status</span>
                      <span className="channel"><FontAwesomeIcon
                                        style={{ marginRight: 10 }}
                                        icon={faTv}
                                    />Channel ID</span>
                      <span className="action">Action</span>
                    </div>
                    {calls.map((call) => {
                            return (
                        <div className="flex-table-item" data-product-id="107">
                            <div className="product">
                                <span className="product-name">
                                    <FontAwesomeIcon
                                        style={{ marginRight: 10 }}
                                        icon={faUser}
                                    />
                                    {call.callerEmail}
                                </span>
                            </div>
                            <div className="date">
                                <span>
                                    {new Date(
                                        call.lastUpdated
                                    ).toLocaleString()}
                                </span>
                            </div>
                            <div className="status">
                                <span className="has-status">
                                    {call.status}
                                </span>
                            </div>
                            <div className="channel">
                                <span className="has-status">
                                    {call.channel}
                                </span>
                            </div>
                            <div className="action">
                                <span className="has-status">
                                    {call.status === "answered" && (
                                        <Button
                                            rounded
                                            size="small"
                                            color="primary"
                                            onClick={() =>
                                                navigate(`/channel/${call.channel}`)
                                            }>
                                            Re-join
                                        </Button>
                                    )}
                                </span>
                            </div>
                        </div>
                    );
                    })}
                  </div>
            </div>
    );
}
