import { Button, Modal } from "react-bulma-components";
import CallAnimation from "./CallAnimation";

export default function OutgoingCallModal({
    calleeEmail,
    onCancel
}: {
    calleeEmail: string | undefined;
    onCancel: () => void;
}) {
    return (
        <Modal show showClose={false}>
            <Modal.Card>
                <Modal.Card.Header showClose={false}>
                    <Modal.Card.Title>Calling {calleeEmail || "..."}</Modal.Card.Title>
                </Modal.Card.Header>
                <Modal.Card.Body>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <CallAnimation grow />
                    </div>
                </Modal.Card.Body>
                <Modal.Card.Footer>
                    <Button rounded onClick={onCancel}>
                        Cancel
                    </Button>
                </Modal.Card.Footer>
            </Modal.Card>
        </Modal>
    );
}
