import { ReactNode, useEffect } from "react";
import { Level } from "react-bulma-components";
import { Contact } from "../../js/firestore/Contact";
import feather from 'feather-icons';

interface ContactItemProps {
    style?: React.CSSProperties,
    contact: Contact;
    children?: ReactNode;
}

const ContactItem: React.FC<ContactItemProps> = ({ style, contact, children }) => {
    useEffect(() => {
        feather.replace(); 
      }, []);

    return (
        // <Level style={style}>
        //     <Level.Side>
        //         <Level.Item>
        //             {contact.email}
        //         </Level.Item>
        //     </Level.Side>

        //     <Level.Side align="right">
        //         <Level.Item>
        //             {children}
        //         </Level.Item>
        //     </Level.Side>
        // </Level>
        <li className="wishlist-item">
            <div className="item-wrapper">

            <i data-feather="user"></i>

            <span className="product-info">
                <a>{contact.email}</a>
                <span>Office</span>
            </span>
            <div className="action">
                {children}
            </div>
            </div>
        </li>
    );
};

export default ContactItem;
