import { useState } from "react";
import { Form, Button, Heading, Box, Notification, Section } from "react-bulma-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import holorayLogo from "../assets/images/png/logo-dark.png";

export default function LoginRegister() {
    const [register, setRegister] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();
    const [searchParams,] = useSearchParams();

    async function onSubmit(e: any) {
        e.preventDefault();

        try {
            if (register) {
                await createUserWithEmailAndPassword(getAuth(), email, password);
            }
            else {
                await signInWithEmailAndPassword(getAuth(), email, password);
            }

            navigate(searchParams.get("redirect") ?? "/dashboard");
        } catch (error: any) {
            console.error(error);
            setErrorMessage(error.message);
        }
    }

    return (
        <Section className="shop-wrapper" style={{ display: "flex", flexDirection: "column", maxWidth: 800, margin: "auto" }}>
            {errorMessage && <Notification color="danger">{errorMessage}</Notification>}
            <div style={{textAlign: "center"}}>
                <img
                    style={{ maxWidth: "75%" }}
                    alt="HOLORAY"
                    src={holorayLogo}/>
            </div>
            <Heading>{register ? "Create a new account" : "Login"}</Heading>
            <Box>
                <form>
                    <Form.Field>
                        <Form.Label>Your email</Form.Label>
                    </Form.Field>
                    <Form.Field>
                        <Form.Control>
                            <Form.Input type="text" placeholder="Enter your email here" autoComplete="username" onChange={e => setEmail(e.target.value)}></Form.Input>
                        </Form.Control>
                    </Form.Field>
                    <Form.Field>
                        <Form.Label>Password</Form.Label>
                    </Form.Field>
                    <Form.Field>
                        <Form.Control>
                            <Form.Input type="password" placeholder="Enter a secure password here" autoComplete="current-password" onChange={e => setPassword(e.target.value)}></Form.Input>
                        </Form.Control>
                    </Form.Field>
                    <Button rounded fullwidth color="primary" onClick={onSubmit}>{register ? "Register" : "Login"}</Button>
                </form>
            </Box>
            <Button text onClick={() => setRegister(!register)}>{register ? "Or login with an existing account" : "Or create a new account"}</Button>
        </Section>
    );
}
