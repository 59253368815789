import { useState } from "react";
import { Button, Heading, Section, Notification } from "react-bulma-components";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import {useUserContext} from "../../contexts/UserProvider";

export default function RequestPasswordReset() {
    const { user } = useUserContext();
    const [sent, setSent] = useState(false);

    async function sendRequest() {
        if (user) {
            await sendPasswordResetEmail(getAuth(), user.email as string);
            setSent(true);
        }
    }

    return (
        <Section>
            <Heading>Request Password Reset</Heading>
            <p>An email will be sent to <b>{user?.email}</b>. Follow the instructions in the email to reset your password.</p>
            <Button rounded color="primary" onClick={sendRequest} style={{ marginTop: 10, marginBottom: 10 }} disabled={sent}>Send Request</Button>

            {sent &&
                <Notification color="success">Request sent successfully.</Notification>
            }
        </Section>
    );
}
