import React from 'react';

const CategoryQuickView: React.FC = () => {
  return (
    <>
       <div className="category-quickview">
            <div className="inner">
                <ul className="category-menu">
                <li>
                    <a href="/">
                    <span>HoloXR</span>

                    
                    </a>
                </li>
                <li>
                    <a href="/">
                    <span>HoloMed</span>
                    {/* <!--img src="assets/img/icons/office.svg" alt=""--> */}
                    
                    </a>
                </li>
                <li>
                    <a href="/">
                    <span>HoloCare</span>
                    {/* <!--img src="assets/img/icons/kids.svg" alt=""--> */}
                   
                    </a>
                </li>
                <li>
                    <a href="/">
                    <span>HoloRehab</span>
                    {/* <!--img src="assets/img/icons/kitchen.svg" alt=""--> */}
                   
                    </a>
                </li>
                <li>
                    <a href="/">
                    <span>HoloSafe</span>
                    {/* <!--img src="assets/img/icons/accessories.svg" alt=""--> */}


                    </a>
                </li>
                <li>
                    <a href="/">
                    <span>HoloBox</span>
                    {/* <!--img src="assets/img/icons/all.svg" alt=""--> */}
                   
                    </a>
                </li>
                </ul>
            </div>
       </div>

    </>
  );
};

export default CategoryQuickView;
